import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image from "../../logos/photos/Picture111.png"
import image2 from "../../logos/photos/IMG_20250125_090512.jpg"

import { useNavigate } from 'react-router-dom';

export default function Membrane() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE’s Innovative Membrane for
Precise Stiffness Detection in MIS
             </h3>
             <p>PALPABLE researchers have achieved a significant breakthrough in tactile sensing technology
for minimally invasive surgery (MIS). Researchers Zhenyu Zhang, Martin Angelmahr, Ahmad
Abdalwareth and Wolfgang Schade from <a href='https://www.hhi.fraunhofer.de/index.html'>Fraunhofer Heinrich Hertz Institute</a>, Abu Bakar
Dawood and Kaspar Althoefer from <a href='https://www.qmul.ac.uk/'>Queen Mary University</a> of London and Georgios Violakis
and Panagiotis Polygerinos from <a href='https://hmu.gr/en/home/'>Hellenic Mediterranean University</a> have successfully created a
novel palpation probe that can detect tissue stiffness using fiber Bragg gratings (FBGs).</p>
              <p>The multinational, multidisciplinary consortium members had the chance to get to know each other, present their organisations, institutions and companies and work. 
                Additionally, initial technical planning, discussion of the following steps as well as the focal project points.</p>
                <h4>Innovative Fiber Optic Technology</h4>
                <p>The newly developed sensor utilises FBGs - periodic variations in the refractive index of an
optical fiber core - inscribed point-by-point with femtosecond laser. These FBGs are integrated
into a pneumatically actuated silicone membrane positioned at the tip of a 15mm diameter
palpation probe, compatible with standard trocar ports used in MIS procedures.</p>
<p>FBG sensors are strategically positioned at the probe tip, enabling the system to not only detect
the force applied to tissue but also precisely localize where that force is being applied. This
spatial awareness is crucial for surgeons performing delicate procedures.</p>
  <img id='img5'
                  src={image}
                  style={{borderRadius:'10px'}}
                 
                  className="img-fluid"
                />
                <br/>

<h4>Remarkable Sensitivity</h4>
                <p>The system shows exceptional sensitivity, capable of detecting forces as small as 0.01N, with a
working range of up to 2N. This high-precision sensing capability will help surgeons detect
subtle differences in tissue characteristics.</p>
<p>This innovative technology represents a significant step toward bringing enhanced tactile
sensing to minimally invasive surgery.</p>

<h4>Mimicking the Sense of Touch</h4>
                <p>One of the most significant limitations in current MIS procedures is the loss of tactile feedback that surgeons would typically rely on during open surgery. The PALPABLE sensor aims to restore this crucial sensory input by providing real-time data on tissue characteristics.</p>
<p>The pneumatic membrane design offers a particularly innovative feature: by adjusting the air pressure within the membrane, the system can modify its sensitivity range. This allows the sensor to adapt to different tissue types and stiffness levels, much like how a surgeon might vary finger pressure during manual palpation.</p>

<h4>Detecting Cancer Through Tissue Stiffness</h4>
                <p>The research demonstrates that the sensor can effectively distinguish between materials of
different stiffness levels. In tests using silicone samples of varying shore hardness (similar to
how tumors might feel firmer than surrounding tissue), the system showed clear differences in
wavelength shifts when contacting softer versus harder materials.</p>
<p>This sensor is an integral component of the PALPABLE tool, which aims to enable surgeons to
identify cancerous tissues during MIS by detecting variations in tissue stiffness.</p>
<figure id='img5'> <img 
                  src={image2}
                  style={{borderRadius:'10px',width:'500px'}}
                 
                  className="img-fluid"
                />
                <figcaption style={{width:'620px', fontSize:'13px'}}>Figure 2: Z. Zheng presenting the research at SPIE Photonics West</figcaption>
                </figure>
                <br/>
<p>The research was presented at <a href='https://spie.org/'>SPIE </a>Photonics West 2025 by Z. Zhang and won the Best
Presentation Award for Medical Diagnostics, Treatment, and Environmental Applications.</p>
<p>For those with access to the SPIE digital library you can read the full paper <a href="https://www.spiedigitallibrary.org/conference-proceedings-of-spie/13310/1331002/Fiber-Bragg-grating-based-tactile-sensing-tool-for-minimally-invasive/10.1117/12.3039246.short">here.</a></p>
              </div>
            </div>
          
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
