import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image1 from "../../logos/photos/Image_20250114_71038_612.jpg"
import image2 from "../../logos/photos/IMG_2683.jpg"
import image3 from "../../logos/photos/image23.jpeg"

import { useNavigate } from 'react-router-dom';

export default function Madrid() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE Consortium Meets in Madrid for Key Project Review

             </h3>
             <p>The PALPABLE consortium gathered in Madrid on January 14-15, 2025, hosted by Medtronic, to review project progress and discuss key integration milestones as the project enters its third year. The two-day meeting brought together partners from across Europe to align on technical developments and plan upcoming presentations and events.</p>
             <p>Partners <a href='https://www.linkedin.com/company/tech-hive-labs/'>Tech Hive Labs (THL)</a>, <a href='https://hmu.gr/en/home/' target="_blank">Hellenic Mediterranean University</a> ,
             <a href='https://www.medtronic.com/me-en/index.html?intcmp=mdt_com_country_selector_dropdown_atlasr22017' target="_blank"> Medtronic</a>,  
             <a href='https://bendabl.com/' target="_blank">Bendabl</a>, 
             <a href='https://en.unito.it/' target="_blank"> Università degli Studi di Torino</a>,  
             <a href='https://www.hhi.fraunhofer.de/en/departments/fs/research-groups/fiberlab.html' target="_blank"> Fraunhofer HHI</a>,
             <a href='https://eaes.eu/' target="_blank"> The European Association of Endoscopic Surgery</a>, 
             <a href='https://hmu.gr/en/home/' target="_blank"> Sofradim Production SASU</a>, 
             <a href='https://www.qmul.ac.uk/' target="_blank"> Queen Mary University of London</a>, 
             <a href='https://www.essex.ac.uk/' target="_blank"> University of Essex</a> shared comprehensive updates on their work and technical achievements. A dedicated integration workshop focused on coordinating efforts for developing the working demo of the tool.</p>
                
               <p>
               The consortium also addressed important strategic topics, including intellectual property considerations and the review of key performance indicators and project risks. Looking ahead, the partners planned their participation in several upcoming workshops and events aimed at engaging with the clinical and research communities.

                </p> 
                <p>As PALPABLE moves forward, the focus remains on successful integration and demonstration of its innovative sensing technologies for minimally invasive surgery, with clear plans established for the coming months of development and testing.</p>
                </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={image2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={image1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
          
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
