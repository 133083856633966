import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../components/others/ChangePassword";
import ComingSoon from "../components/others/ComingSoon";
import LoginTwo from "../components/others/LoginTwo";
import NotFound from "../components/others/NotFound";
import PasswordReset from "../components/others/PasswordReset";
import ThankYou from "../components/others/ThankYou";
import Login from "../pages/Login";
import HomeOne from "../themes/index-1/HomeOne";
import HomeTwo from "../themes/index-2/HomeTwo";
import HomeThree from "../themes/index-3/HomeThree";
import HomeFour from "../themes/index-4/HomeFour";
import HomeFive from "../themes/index-5/HomeFive";
import HomeSix from "../themes/index-6/HomeSix";
import HomeSeven from "../themes/index-7/HomeSeven";
import HomeEight from "../themes/index-8/HomeEight";
import News from "../pages/News";
import Outcomes from "../pages/Outcomes";
import TeamDetails from "../pages/TeamDetails";
import Download from "../pages/Download";
import Project from "../pages/Project";
import Faq from "../pages/Faq";
import SignupTwo from "../components/others/SignupTwo";
import SignupPage from "../pages/SignupPage";
import BlogGrid from "../pages/BlogGrid";
import BlogWithSidebar from "../pages/BlogWithSidebar";
import BlogDetails from "../pages/BlogDetails";
import ScrollToTop from "./ScrollToTop";
import Partners from "../pages/Partners";
import Kickoff from "../pages/blog/Kickoff";
import EAES from "../pages/blog/EAES";
import Surgery from "../pages/blog/Surgery";
import Palpablepartners from "../pages/blog/Palpablepartners";
import ROAH from "../pages/blog/ROAH";
import Optical from "../pages/blog/Optical";
import Maastricht from "../pages/blog/Mastricht";
import Queen from "../pages/blog/Queen";
import Hamlyn from "../pages/blog/Hamlyn";
import TAROS from "../pages/blog/TAROS";
import Haptic from "../pages/blog/Haptic";
import RSS from "../pages/blog/RSS";
import Showcases from "../pages/blog/Showcases";
import OpticalSensors from "../pages/blog/OpticalSensors";
import Madrid from "../pages/blog/Madrid";
import Medtronic from "../pages/blog/Medtronic";
import Membrane from "../pages/blog/Membrane";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeTwo />} />
        <Route path="/news" element={<News />} />
        <Route path="/outcomes" element={<Outcomes />} />
        <Route path="/project" element={<Project />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/blog-grid" element={<BlogGrid />} />
        <Route path="/blog-sidebar" element={<BlogWithSidebar />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/PALPABLE_project_kicks_off_with_a_bang" element={<Kickoff/>} />
        <Route path="/The_EAES_congress_in_Rome_embraces_the_PALPABLE_M6_meeting" element={<EAES/>} />
        <Route path="/ADDRESSING_A_DRAWBACK_OF_MINIMALLY_INVASIVE_SURGERY" element={<Surgery/>} />
        <Route path="/PALPABLE_partners_meet_at_Fraunhofer_HHI_to_discuss_progress_and_future_plans" element={<Palpablepartners/>} />
        <Route path="/THE ROAD AHEAD FOR MINIMALLY INVASIVE THERAPY OF DIGESTIVE CANCERS" element={<ROAH/>} />
        <Route path="/Optical fibre sensors: From oil rigs to minimally invasive surgery" element={<Optical/>} />
        <Route path="/PALPABLE experts consult EAES surgeons in Maastricht" element={<Maastricht/>} />
        <Route path="/PALPABLE consortium explores latest project advances at Queen Mary University" element={<Queen/>} />
        <Route path="/PALPABLE partners co-host workshop exploring the role of touch in surgery at Hamlyn Symposium" element={<Hamlyn/>} />
        <Route path="/Latest PALPABLE research presented at TAROS 2024" element={<TAROS/>} />
        <Route path="/Inflated Haptic Membrane Sensors: Bringing Back the Surgeon’s Touch" element={<Haptic/>} />
        <Route path="/New Research Explores Integration of PALPABLE Technology with Robotic Surgery Systems"  element={<RSS/>} />
        <Route path="/Research Showcases Capabilities of PALPABLE Optical Fiber-Based Force Sensor"  element={<Showcases/>} />
        <Route path="/Engineering Flexibility: How Flexible Optical Sensors Could Transform Surgical Tools"  element={<OpticalSensors/>} />
        <Route path="/PALPABLE Consortium Meets in Madrid for Key Project Review"  element={<Madrid/>} />
        <Route path="/PALPABLE partners explore Hugo™ robotic-assisted surgery system with Medtronic"  element={<Medtronic/>} />
        <Route path="/PALPABLE’s Innovative Membrane for Precise Stiffness Detection in MIS"  element={<Membrane/>} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
