import React, {useState} from "react";
import FeatureImg from "../components/features/FeatureImg";
import FeatureImgThree from "../components/features/FeatureImgThree";
import Features from "../components/features/Features";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import AllReview from "../components/testimonial/AllReview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import hmu from '../logos/hmu.png'
import unito from '../logos/unito.png'
import twi from '../logos/THL_logo_main.png'
import sofradim from '../logos/sofradim.jpg'
import qmul from '../logos/qmul.png'
import medtronic from '../logos/medtronic.png'
import fraunhofer from '../logos/fraunhofer.png'
import essex from '../logos/essex.png'
import eaes from '../logos/eaes.png'
import bendabl from '../logos/bendabl.svg'


export default function Partners() {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [twiShow, settwiShow] = useState(false);
  const [bendablShow, setbendablShow] = useState(false);
  const [essexShow, setessexShow] = useState(false);
  const [sofradimShow, setsofradimShow] = useState(false);
  const [unitoShow, setunitoShow] = useState(false);
  const [hmuShow, sethmuShow] = useState(false);
  const [eaesShow, seteaesShow] = useState(false);
  const [fraunhoferShow, setfraunhoferShow] = useState(false);
  const [qmulShow, setqmulShow] = useState(false);
  const [medtronicShow, setmedtronicShow] = useState(false);
  return (
    <Layout>
      <Navbar />
      <PageHeader HeaderTitle="Partners" Parent="Pages" PageTitle="Partners" />
      <section className="promo-section mt-5 ptb-100">
        
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <section class="section section-default mt-none mb-none">
<div class="container">
<p>The consortium partners bring to the (surgical) table significant and diverse technological and scientific expertise on soft robotics design, continuum mechanics, non-linear pneumatic control and multiple sensing modalities, from haptic sensor and probe design to stiffness reconstruction algorithms to distance sensing modules.
</p>
<br/>
<strong>
<div class="row">

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={hmu} onClick={() => sethmuShow(true)}/>
<Modal 
        show={hmuShow}
        onHide={() => sethmuShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>HMU 
        <span> <a target="_blank" href="https://hmu.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => sethmuShow(false)}/>
        </Modal.Header>
        <Modal.Body>The Hellenic Mediterranean University (EL.ME.PA., English: Hellenic Mediterranean University‎, H.M.U.) is a public academic institution of Greece, with headquarters in Heraklion, Crete and branches in the cities of Chania, Rethimno, Agios Nikolaos, Ierapetra and Sitia. The Hellenic Mediterranean University was founded in early 2019 (Law 4610/2019, Official Gazette 70, issue A', 07/05/2019), after the passing of the multi-law bill. Today it consists of six Faculties, with more than 15,000 undergraduate and postgraduate students and approximately 400 teaching and research staff. Its academic history starts in the late 1970.
</Modal.Body>
<Modal.Body>
The main goal of the School of Engineering is to provide high quality education and training in the field of Engineering. The School offers undergraduate programs corresponding to level 7 (integrated masters) of the European Qualifications Framework (EQF), postgraduate programs corresponding to level 7, as well as doctoral programs to obtain a PhD degree (level 8).
</Modal.Body>
  <Modal.Body>The interdepartmental Control Systems & Robotics Laboratory (CSRL) is a partnership of the Department of Electrical & Computer Engineering and the Department of Mechanical Engineering of the School of Engineering of the Hellenic Mediterranean University (HMU), and promotes research, development, and education activities in the areas of control systems, robotics, soft robotics, automation and mechatronics. The project’s research and development efforts are hosted at the Control Systems and Robotics Laboratory (CSRL) of the Hellenic Mediterranean University. CSRL has extensive expertise and modern facilities for prototype development and testing of mechatronics and robotics systems.
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={unito} onClick={() => setunitoShow(true)}/>
<Modal 
        show={unitoShow}
        onHide={() => setunitoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>UNITO
        <span> <a target="_blank" href="https://www.unito.it/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setunitoShow(false)}/>
        </Modal.Header>
        <Modal.Body>The University of Turin (UNITO) was founded in 1404. It counts almost 4.000 employees and hosts 80,000, including 1,800 PhD/post-doc/research fellows. It is structured into 27 departments, encompassing all scientific disciplines. It deals with 500 new projects per year at both national and international levels since 2016, with an overall income of about 250 M€.
</Modal.Body>
<Modal.Body>
The Department of Surgical Sciences (DSS) belongs to the School of Medicine, and it is composed of 91 members belonging to all the different surgical disciplines. It represents the largest Department of Surgical Sciences in Italy. The faculty staff covers leading positions in the international arena of scientific research. It successfully participated in projects regarding the use of new technologies in the surgical and endoscopic area within FP5, FP6, FP7, H2020 and now HEU frameworks. Research activities are focused on: minimally invasive therapy, robotics, clinical imaging and interventional radiology, innovative materials for surgical applications
</Modal.Body>
  <Modal.Body>DSS hosts the Minimally Invasive Therapy and Innovation Center (MITIC), which includes 2 dry labs and wet labs of about 300 sqm each, a virtual lab for laparoscopy and flexible endoscopy, and operating rooms for clinical activities. The MITIC is equipped with all the most recent technology for minimally invasive surgery. In 2018-2022 DSS was awarded the “Department of Excellence” grant by the Ministry of University with 8.7 M€.
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={sofradim} onClick={() => setsofradimShow(true)}/>
<Modal 
        show={sofradimShow}
        onHide={() => setunitoShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Sofradim
        <span> <a target="_blank" href="https://www.linkedin.com/company/sofradim-production"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setsofradimShow(false)}/>
        </Modal.Header>
        <Modal.Body>Sofradim Production from France, one of the Medtronic entities, participates in the PALPABLE project. It is part of Medtronic Surgical Operating unit (OU). The OU technologies are used across 50+ procedures (including lung, colorectal, gynaecological, obesity
& metabolic, general surgeries) and in all types of surgeries (open, laparoscopic, endoluminal
& robotic-assisted surgeries). They are used for access (i.e. Entry into the abdominal or
thoracic cavity), Dissection (i.e. Identification of critical structures and division of attachments
and blood vessels), Resection/Repair (i.e. Removal or manipulation of target tissue),
Resection/Repair (i.e. Removal or manipulation of target tissue) and Visualization (i.e. Enabling
surgeons to see the unseen in minimally invasive surgeries). Sofradim will bring this
expertise to the project by contributing to the standardisation of the PALPABLE tool.
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={essex} onClick={() => setessexShow(true)}/>
<Modal 
        show={essexShow}
        onHide={() => setessexShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Essex
        <span> <a target="_blank" href="https://www.essex.ac.uk/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setessexShow(false)}/>
        </Modal.Header>
        <Modal.Body>The University of Essex (UESS) is gold-rated in the Teaching Excellence Framework 2017, and we're in the top 25 for research quality in The Times and Sunday Times Good University Guide. We’re committed to excellence in research for the benefit of individuals, communities, and the industry, and we provide extensive open access to our research repository.
</Modal.Body>
<Modal.Body>
The Essex Artificial Intelligence Innovation Centre brings together the research skills, expertise and technologies across our Faculty of Humanities, Science and Health, and Social Sciences to address the grand challenges facing citizens, workers, and all those technologists in sectors undergoing digital transformation to create new – or modify existing - business processes, culture, and customer experiences to meet changing business and market requirements. We have the ambition of developing a sustainable funding model for our research fit for the rigours of the emerging UK research environment. We reach beyond the confines of academia as we have a clear mandate to work with business partners in the UK and overseas. We’re different because we bring a commercial mindset to our work. We start with and are responsive to business needs. We work hard to make sure they are getting the outputs they need when they need them. We’re not bound by tradition and offer fresh perspectives and innovation in all we do.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={twi} onClick={() => settwiShow(true)}/>
<Modal 
        show={twiShow}
        onHide={() => settwiShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>THL
        <span> <a target="_blank" href="https://www.twi-hellas.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => settwiShow(false)}/>
        </Modal.Header>
        <Modal.Body>TECH HIVE LABS (THL) is a European centre of excellence in Robotics and AI, where bold ideas and applied research turn into industrial solutions with real impact. We blend the curiosity of a lab with the precision of a business leader to drive technology engineering and transform experimentation into real-world capability, empowering entrepreneurs and enterprises to innovate with confidence and deliver measurable results.
</Modal.Body>
<Modal.Body>
Our motto is "Serious Playground. Real Impact.", which is also our way of working. We offer diverse engagement models, from collaborative projects and knowledge exchange to engineering consulting and technology transfer, to suit the specific needs of research collaborators and industry partners and ensure that every endeavour drives real results.
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={bendabl} onClick={() => setbendablShow(true)}/>
<Modal 
        show={bendablShow}
        onHide={() => setbendablShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>bendabl
        <span> <a target="_blank" href="https://bendabl.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setbendablShow(false)}/>
        </Modal.Header>
        <Modal.Body>Bendabl is a high-tech SME specialising in pneumatically actuated soft robotic solutions. Based in Athens, Greece, the company’s team has over 10 years of experience across the entire lifecycle of customised robotics development, from mechanical design, and Finite-Element modelling to soft robot fabrication techniques and control algorithms.
</Modal.Body>
<Modal.Body>
Bendabl is comprised of a team of engineers with a passion about robotics of a very special breed: soft. The company’s core vision is that the future belongs to robots that can reach the pinnacle of adaptability; the very change of their physical properties.
</Modal.Body>
  <Modal.Body>Having accumulated deep expertise in pneumatically-actuated mechanisms and soft structure manufacturing, Bendabl aspires to be the pioneers of this new generation of robots. To push the boundaries of their capability. To broaden the range of applications they can help with. And most importantly, to flatten the learning curve of both developing and deploying them.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={eaes} onClick={() => seteaesShow(true)}/>
<Modal 
        show={eaesShow}
        onHide={() => seteaesShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>EAES
        <span> <a target="_blank" href="https://eaes.eu/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => seteaesShow(false)}/>
        </Modal.Header>
        <Modal.Body>The European Association for Endoscopic Surgery (EAES) was founded in 1990 and since then has become a growing and dynamic organisation that continues to play a leading role in Endoscopic Surgery and Allied Interventional Techniques.  Aside from organising annual Top Level European Congresses in different European countries, the EAES activities in education, training, research, development, and publication are effected through its various Committees.
</Modal.Body>
<Modal.Body>
Vision: Safe and sustainable surgery for all.
</Modal.Body>
  <Modal.Body>Mission:
Drive continuous education and quality assurance to improve patient outcome
Promote ground-breaking innovation and technology in surgery related health care
Embrace equity, honesty, transparency and respect for patients, members and environment
Believe in patient safety, innovations, quality,  sustainability and training.
</Modal.Body>
<Modal.Body>
<ul>
  <li>•	Respect</li>
  <li>•	Inclusiveness</li>
  <li>•	Fairness</li>
  <li>•	Honesty</li>
  <li>•	Equality</li>
</ul>
</Modal.Body>
<Modal.Body>The EAES has close to 4,000 members from more than 110 countries and, together with the US-based association (SAGES), publishes the scientific journal Surgical Endoscopy.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={medtronic} onClick={() => setmedtronicShow(true)}/>
<Modal 
        show={medtronicShow}
        onHide={() => setmedtronicShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>Medtronic
        <span> <a target="_blank" href="https://europe.medtronic.com/xd-en/index.html"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setmedtronicShow(false)}/>
        </Modal.Header>
        <Modal.Body>Medtronic is the global leader for medical devices -alleviating pain, restoring health and
extending life for millions of people worldwide - the global pioneer of advanced
surgical instruments. It has the purpose of delivering meaningful innovation to enhance clinical
decision-making, improve patient outcomes, and drive economic value in open, laparoscopic
and robotic-assisted surgery. Medtronic has a wide range of CE-marked and FDA approved
products and therapies in the surgical field.
</Modal.Body>
<Modal.Body>
Medtronic Ibérica from Spain participates in the PALPABLE project. It is highly experienced in creating and developing commercialisation and exploitation strategies as well as dissemination and communication plan when considering solutions for the healthcare industry and European Projects. With its expertise in the field, Medtronic Iberica will also contribute to defining the Exploitation Plan to achieve the goals of the PALPABLE project.
</Modal.Body>
      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={qmul} onClick={() => setqmulShow(true)}/>
<Modal 
        show={qmulShow}
        onHide={() => setqmulShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>QMUL
        <span> <a target="_blank" href="https://www.qmul.ac.uk/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setqmulShow(false)}/>
        </Modal.Header>
        <Modal.Body>
        Queen Mary University of London (QMUL) is one of the UK's leading research-focused higher education institutions (HEI) and part of the 24 Russell Group UK HEIs. Founded in 1785 as the London Medical College, Queen Mary University of London (QMUL) is the amalgamation of four historic medical institutions, including England’s first medical school. It was officially granted university status in 1887 and now offers over 240 degree programmes across three faculties: Humanities and Social Sciences, Science and Engineering, and Medicine and Dentistry. QMUL has 32,000+ students and nearly 4,500 members of staff, and an annual turnover of over £535m.
        </Modal.Body>
        <Modal.Body>
        The Centre for Advanced Robotics @ Queen Mary (ARQ) has a state-of-the-art robotics lab for teaching and research, since October 2016. The lab is equipped with the most modern robotic systems, including mobile robots, robot arms and manipulation devices as well as specialised equipment including medical robotics devices, soft robot fabrication systems and sensor manufacturing and testing equipment. The lab also boasts rapid prototyping facilities which will be used to fabricate prototypes for this project.
        </Modal.Body>
        <Modal.Body>
</Modal.Body>

      </Modal>
</div>
</div>

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={fraunhofer} onClick={() => setfraunhoferShow(true)}/>
<Modal 
        show={fraunhoferShow}
        onHide={() => setfraunhoferShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h6 className="title" style={{color:'#dadada'}}>FRHHI
        <span> <a target="_blank" href="https://www.fraunhofer.de/en.html"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h6> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setfraunhoferShow(false)}/>
        </Modal.Header>
        <Modal.Body>Fraunhofer is Europe’s largest application-oriented research organisation. Our research efforts are entirely focused on people’s needs in the areas of health, security, communication, energy, and the environment. As a result, the work undertaken by our researchers and developers has a significant impact on people’s lives. The organisation pursues the implementation of innovative research findings in industrial and social applications. Its work is based on a dynamic balance between applied basic research and innovative development projects.
</Modal.Body>
<Modal.Body>
The Heinrich Hertz Institute (HHI), a member of the Fraunhofer society, is a world leader in the development of mobile and optical communication networks and systems, fiber optical sensor systems, and video signal processing and coding. The institute’s research is consistently focused on its areas of expertise and offers attractive technical solutions for current and future development demands in society and markets. As an example, the Fraunhofer HHI’s developments in integrated optics and (fiber) optic measurement systems enable new and innovative laser and sensor concepts. In the frame of the Palpable project, a non-planar optical sensor array optimized for minimal invasive surgery will be developed. 
</Modal.Body>
      </Modal>
</div>
</div>

</div>
</strong>
</div>
</section>
              </Col>
            </Row>
            </Container>
          
     
      </section>
      
      <Footer />
    </Layout>
  );
}
