import React from "react";

export default function PromoTwo() {
  return (
    <>
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div className="section-heading mb-5">
              
                <h3 className="mb-6" style={{color:'#12b59e'}}>
                PALPABLE in a nutshell
</h3>
              </div>
             
            </div>
         
          
          </div>
          <p>PALPABLE aspires to develop a next-generation palpation tool for identifying and visualising tissue abnormalities in minimally invasive surgeries (MIS). The system combines cutting-edge technologies using advanced optical sensing, soft robotics, and artificial intelligence to bring back the surgeon's sense of touch, and enable tissue characterisation based on depth, size and stiffness.  </p>
          <p>At the heart of the technology is a flexible probe equipped with multiple sensing modalities. A specialised tactile membrane and curvature sensors work in concert to measure tissue stiffness, while an innovative pneumatic actuation system allows precise steering to examine hard-to-reach areas. Purpose-built visualisation software processes the sensor data in real-time, creating intuitive stiffness maps that help surgeons identify tumors and other tissue anomalies. </p>
        <p>The system emphasises both practicality and affordability through its use of low-cost optical and pneumatic components. Its design features a detachable probe tip that can be disposed of after use, eliminating the need for complex sterilisation procedures. The project unites leading European researchers, medical device companies, and surgeons in developing this technology, with a clear focus on clinical adoption and improving patient outcomes in minimally invasive procedures.</p>
        </div>
      </section>
    </>
  );
}
