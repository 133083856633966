import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image1 from "../../logos/photos/medtronicimage.png"
import image2 from "../../logos/photos/Picture1.png"


import { useNavigate } from 'react-router-dom';

export default function Medtronic() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                PALPABLE partners explore Hugo™ robotic-assisted surgery system with Medtronic
             </h3>
             <h5>Virtual Hands-on Experience </h5>
             <p>
             The PALPABLE consortium had the unique opportunity to gain insights into robotic-assisted surgery (RAS) during a dedicated workshop with Medtronic, the owner of the <a href='https://www.medtronic.com/covidien/en-gb/robotic-assisted-surgery/hugo-ras-system.html'>Hugo™ </a> system and PALPABLE partner.</p>
               <p>
               In a live online session, Medtronic's training manager, Diego Colas, demonstrated how surgeons use the robot. Using the live streaming capabilities of the HUGO™ RAS system, participants observed both the movements of the surgeon console controls and the robotic arms in the training room, while simultaneously viewing the instruments performing surgical exercises through the endoscope image.
                </p> 
                <h5>Understanding Surgical Needs </h5>
                <p>The interactive session was designed to help research partners understand the needs of surgeons using RAS systems, how they control tools through the robotic console, and to gain insights into how the PALPABLE tool could be integrated into such systems.</p>
                <h5>Advantages of Robotic Surgery  </h5>
                <p>Diego Colas explained how some challenges of laparoscopic surgery can be mitigated in RAS:</p>
                <ul id='ul1' style={{listStyleType:'circle',paddingLeft:'20px'}}>
                  <li>The surgeon can be seated in an ergonomic position, rather than standing and bending over, and use wristed instruments with seven degrees of freedom</li>
                  <li>The robot compensates for natural slight hand tremors, enabling fine, precise movements of the tools</li>
                </ul> <br/>
                <p>These are the kind of advances that elevate surgeons’ skills and which can bring the benefits of minimally invasive surgery to more procedures and patients. </p>
                <p>The experience highlighted Hugo™'s key innovations:</p>
                <ul id='ul1' style={{listStyleType:'circle',paddingLeft:'20px'}}>
                  <li>Open console for increased comfort and communication within the surgical team.</li>
                  <li>Modular, independent arms for flexibility and portability</li>
                </ul>
                <br/>
                <h5>Path to Integration  </h5>
              <p>The PALPABLE tool is being developed in close collaboration with surgeons, through the European Association for Endoscopic surgery <a href='https://eaes.eu/'>(EAES)</a>, a key partner in the project. A Master's thesis has explored the requirements the PALPABLE tool should have to be able to be integrated into Hugo™ without disrupting clinical workflows, examining how PALPABLE's haptic sensing capabilities could enhance robotic surgery.</p>
              <p>PALPABLE is being designed to be used as a stand-alone tool, as well as offering potential for integration into RAS, bringing the sense of touch to minimally invasive surgery.</p>
              <h5>Learn More </h5>
              <p>Discover how PALPABLE could transform robotic surgery by reading this <a href='https://palpable-project.eu/New%20Research%20Explores%20Integration%20of%20PALPABLE%20Technology%20with%20Robotic%20Surgery%20Systems'>short article</a> and watching the video about the Master’s research <a href='https://www.youtube.com/watch?v=bLLpubCRrbM'>here</a>. These resources showcase the potential integration of PALPABLE's innovative haptic sensing technology with robotic surgical systems.</p>
                </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={image2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={image1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
               
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
